<section class="d-flex justify-content-center align-items-center">
    <div class="modal text-center">
        <img
            src="assets/img/icons/cross.svg"
            (click)="dialogRef.close()"
            class="img-cross cursor-pointer"
        />
        @if (showDecoration) {
            <img
                class="notice-icon"
                [src]="iconModalDecorationPath"
                alt="Vicente de Santo Vecino"
            />
        }
        @if (message.title !== '') {
            <div class="div-h1">
                {{ this.message.title }}
            </div>
        }
        @if (message.message) {
            <p [innerHTML]="this.message.message"></p>
        }
        @if (btnOperation) {
            <div
                class="m-auto d-flex align-items-center flex-direction-column"
            >
                <div class="small-spacing"></div>
                @for (itrBtn of btnOperation; track itrBtn; let first = $first) {
                    <app-button
                        (click)="register(itrBtn)"
                        [data]="itrBtn"
                    ></app-button>
                    @if (first) {
                        <div class="small-spacing"></div>
                    }
                }
            </div>
        }
    </div>
</section>
